
import {defineComponent, onMounted, ref} from 'vue'
import Entities from "@/components/base/common/Entities.vue";
import DataTable from "@/components/datatable/DataTable.vue";
import {ChartType} from "@/core/config/Constant";
import {CompanySelect} from "@/core/composite/composite";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "OverduePendingDocuments",
  components: {DataTable, Entities},
  setup() {
    const clientId = ref('')
    const month = ref(6);
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams(' Overdue Pending Documents',
        [
          {link: false, router: '', text: ' Overdue Pending Documents'}
        ]
      )
    })
    return {
      month,
      ChartType,
      clientId,
      ...CompanySelect(),
    }
  }
})
